<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="./assets/logo.png" />
			<span>Nishcinto</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<Dialog header="Sign Out Confirmation" v-model:visible="displayResponsive" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal='true'>
            <div class="flex p-4">
				<i class="pi pi-info-circle" style="font-size: 1.5rem"></i>
				<p class="pl-2">Do you want to Sign Out?</p>
			</div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="closeResponsive" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="signout" autofocus class="p-button-raised p-button-danger"/>
            </template>
        </Dialog>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="profile" v-tooltip.bottom="'View Profile'">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button p-button p-button-rounded p-button-danger p-button-text" @click="openResponsive" v-tooltip.bottom="'Sign Out'">
					<i class="pi pi-power-off"></i>
					<span>Sign Out</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import { ref } from "vue";
import Axios from 'axios';
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
	setup(){
		const store = useStore();
		const router = useRouter();
		
		let user = ref();
		const displayResponsive = ref(false);


		const getUserData = () => {
			Axios
				.get("api/user")
				.then((res) => {
					// console.log(res);
					if(res.data.response == "success"){
					user.value = res.data.data;
					// console.log(user.value);
					}
				})
				.catch((err) => {
					console.log(err);
				});
			// console.log('user');
		};
		getUserData();

		const openResponsive = () => {
            displayResponsive.value = true;
        };
        const closeResponsive = () => {
            displayResponsive.value = false;
        };

		const profile = () => {
			router.replace({name:'account'});
		};

		const signout = () => {
			store.dispatch('removeUserData');
			router.push({name:'signin'});
		};


		return {
		user,
		getUserData,
		signout,
		profile,
		displayResponsive,
		openResponsive,
		closeResponsive,
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>